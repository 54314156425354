
import { Vue, Component } from "vue-property-decorator";
import Axios from "axios";
// import { SERVER_API_URL } from "../../config";
import { AppConfig } from '@/types/config';
import HeaderCompnent from "../../views/header.vue";
import { PRODUCTS } from "../../constant";
@Component({ components: { HeaderCompnent } })
export default class Dashborad extends Vue {
  public userList = [];
  public userDetails = {
    name: "",
    loginName: "",
    email: null,
    password: null,
    userType: "User",
    customerName: "",
    accountManager: "",
    product: ""
  };
  public store: any = null;
  public deleteUserName:any = null
  public saveAndEdit = false;
  public dialog = false;
  public confirmationDialog = false
  public email:any = null
  public error = {
    name: [(v: any) => !!v || "Name is mandatory"],
    loginName: [(v: any) => !!v || "Login name is mandatory"],
    email: [(v: any) => !!v || "Email is mandatory"],
    password: [(v: any) => !!v || " Password is mandatory"]
  };
  public productSelected: any = [];
  public PRODUCTS: any = [];
  public customerDetails = {
    customerName: "",
    packageId: "",
    projectName: "",
    licenseCount: 0,
    usedCount: 0,
    createdBy: "",
    unlimited: false,
    validTime: new Date(),
    accountManager: "",
    createdDate: new Date(),
    licenseType: "",
    product: "",
    comments: "",
  };
  public customerName :any = [];
  public managerList: any = [];
  public customerData: any = [];

          // Directly access the SERVER_API_URL from the Vue prototype
          get SERVER_API_URL(): string {
    return (this.$config as AppConfig).SERVER_ADDRESS;
  }

  // Access NODE_ENV similarly
  get NODE_ENV(): string {
    return (this.$config as AppConfig).NODE_ENV;
  }

  async getUserList() {
    try {
      let response = await Axios.get(`${this.SERVER_API_URL}/Users/listUser`);
      this.userList = response.data;
    } catch (error) {
      console.log(error);
    }
  }
   openConfirmationDailog(email:any,name : any) {
    this.email  = email ;
    this.deleteUserName = name
    this.confirmationDialog = true    
  }
  async getProducts() {
      try {
        const response = await Axios.get(`${this.SERVER_API_URL}/products`); // Replace with your API endpoint
        if (response.data) {
          this.PRODUCTS = response.data.map((e: any) => ({
            ...e,
            nameVersion: `${e.name} ${e.version}`
          }));
        }
      } catch (error) {
        console.error('Failed to get data', error);
      }
    }

  setEditObject(data: any) {
    try
    {
    this.userDetails = JSON.parse(JSON.stringify(data));
    this.getProducts();
    this.productSelected = this.userDetails?.product?.split(', ');
    this.dialog = true;
    this.saveAndEdit = true;
    }
    catch(error)
    {
      console.log(error);
      this.userDetails = this.emptyObject();
    }
  }
  emptyObject() {
    return {
      name: "",
      loginName: "",
      email: null,
      password: null,
      userType: "User",
      customerName: "",
      accountManager: "",
      product: ""
    };
  }

  async updateUser() {
    try {
      this.userDetails.product = this.productSelected.join(', ');
      let response = await Axios.put(`${this.SERVER_API_URL}/Users/updateUser`, 
        this.userDetails
      );
      if (response.status == 200 || response.status == 201) {
        this.$snotify[response.status == 201 ? 'error':'success'](response.data.message);
        if (response.status == 200) await this.getUserList();
        this.userDetails = this.emptyObject();
        this.dialog = false;
        this.saveAndEdit = false;
        (this.$refs["form"] as any).reset();
      }
    } catch (error) {
      console.log(error);
    }
  }
  closeDialog() {
    this.dialog = false;
    this.saveAndEdit = false;
    this.userDetails = this.emptyObject();
    (this.$refs["form"] as any).reset();
  }
  async saveUser() {
    try {
      if (!(this.$refs["form"] as any).validate()) return;
      let productString = this.productSelected.join(', ');
      this.userDetails.product = productString;
      let response = await Axios.post(`${this.SERVER_API_URL}/Users/saveUser`, this.userDetails
      );
      if (response.status == 200 || response.status == 201) {
        this.$snotify[response.status == 201 ? 'error':'success'](response.data.message);
        (this.$refs["form"] as any).reset();
        this.dialog = false;
        if (response.status == 200) await this.getUserList();
        this.userDetails = this.emptyObject();
      }
    } catch (error) {
      console.log(error);
    }
  }
  async deleteUser() {
    try {
      let response = await Axios.delete(`${this.SERVER_API_URL}/Users/deleteUser`,{ params:{email:this.email}});
      this.$snotify.success(response.data.message);
      this.confirmationDialog = false
      await this.getUserList();
    } catch (error) {
      console.log(error);
    }
  }

  async mounted() {
    this.store = JSON.parse(localStorage.getItem("UserInfo") || '');
    await this.getUserList();
    await this.customerLists();
    await this.getManagerList();
    await this.getProducts();
  }
  async getManagerList() {
    try {
      let response = await Axios.get(`${this.SERVER_API_URL}/Accountmanagers`);
      if (response.data.length > 0) {
        response.data.forEach((p: any) => {
          this.managerList.push(p.name);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  changeAccountManagerValues() {
    
      let manager = this.customerData.find((p: any) => p.customerName.toString() == this.userDetails?.customerName?.toString()) 
      this.userDetails.accountManager = manager.accountManager
  }
  async customerLists() {
    try {
      let response = await Axios.get(`${this.SERVER_API_URL}/Customerdetails`);
      if (response.data?.length > 0) {
        this.customerData = response.data
        response.data.forEach((p: any) => {
          this.customerName.push(p.customerName);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
}
