
import { Vue, Component, Prop } from "vue-property-decorator";
@Component
export default class Header extends Vue {
  @Prop() public changeColor: any;
  @Prop() public page: any;
  public homeButtonClass = "hoverClass";
  public userButtonClass = "hoverClass";
  public customerButtonClass = "hoverClass";
  public managerButtonClass = "hoverClass";
  public mainDashboardButton ="hoverClass"
  public store: any;
 
  async changeRouter() {
    this.$router.push({ name: "Dashboard" });
  }
  async logout() {
    if (JSON.parse(localStorage.getItem("UserInfo") || "")) {
      localStorage.removeItem("UserInfo");
      this.$router.push("/").catch(() => {console.log("Error in Logged out!")});
    }
  }
  async highLightOption() {
    if (this.page == "dashboard") this.homeButtonClass = "changeHoverClass";
    if (this.page == "user") this.userButtonClass = "changeHoverClass";
      if (this.page == "mainDashboard") this.mainDashboardButton = "changeHoverClass";
  }
  mounted() {
    this.highLightOption();
  }
  isAdmin() {
    this.store = JSON.parse(localStorage.getItem("UserInfo") || "");
    console.log("In Admin");
      // Check if the userType is admin
      return this.store && this.store.userType === "Admin";
    }
}
